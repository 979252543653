import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routing/routeConfiguration';
import { LinkTabNavHorizontal } from '../../components';

import css from './UserNav.module.css';
import { map } from 'lodash';
import { compose } from 'redux';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import { connect } from 'react-redux';

const UserNavComponent = props => {
  const { className, rootClassName, currentPage, currentUser } = props;
  const classes = classNames(rootClassName || css.root, className);

  const user = ensureCurrentUser(currentUser);

  const tabs = [
    {
      text: <FormattedMessage id="UserNav.profileSettings" />,
      selected: currentPage === 'ProfileSettingsPage',
      disabled: false,
      linkProps: {
        name: 'ProfileSettingsPage',
      },
    },
    {
      text: <FormattedMessage id="UserNav.accountSettings" />,
      selected: ACCOUNT_SETTINGS_PAGES.includes(currentPage),
      disabled: false,
      linkProps: {
        name: 'ContactDetailsPage',
      },
    },
  ];

  if (user?.attributes?.profile?.protectedData?.userType === 'tutor') {
    tabs.unshift({
      text: <FormattedMessage id="UserNav.yourListings" />,
      selected: currentPage === 'ManageListingsPage',
      linkProps: {
        name: 'ManageListingsPage',
      },
    });
  }

  return (
    <LinkTabNavHorizontal className={classes} tabRootClassName={css.tab} tabs={tabs} skin="dark" />
  );
};

UserNavComponent.defaultProps = {
  currentUser: null,
  className: null,
  rootClassName: null,
};

const { string } = PropTypes;

UserNavComponent.propTypes = {
  currentUser: propTypes.currentUser,
  className: string,
  rootClassName: string,
  currentPage: string.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  return { currentUser };
};

const UserNav = compose(connect(mapStateToProps))(UserNavComponent);

export default UserNav;
